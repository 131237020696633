<template>
    <div style="width: 100%;min-height:100vh;">
        <div style="width: 100%;background: #FFFFFF">
            <div style="width: 1500px;height: 80px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
                <div style="display: flex;align-items: center;align-items: center">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="height: 46px;width: auto;margin: 0 10px;"/>
                    <div style="color: #031F88;font-size: 30px;font-weight: bold"> | 我的需求信息</div>

                    <div style="width: 600px;background: #FFFFFF"></div>
                </div>
                <div style="display: flex;align-items: center;">
                    <AvatarMenu></AvatarMenu>
                </div>
            </div>
        </div>
        <div style="display: flex;justify-content: center">
            <div style="width: 1500px;background-color: #FFFFFF;min-height: 828px;margin-top: 10px;">
                <div v-for="(item,index) in active_list" :key="index"  style="float: left;margin-left: 40px;margin-top: 20px;display: flex">
                    <div @click="selectedswitch(item.type)" :style="selected == item.type?'color:#2970FF;':''">
                        <div style="display: inline-block;">
                            <div :style="item.type == 0?'width: 72px':'width: 110px'" style="height: 21px; font-size: 18px;cursor: pointer; text-align: left;">{{ item.label }}</div>
                        </div>
                        <div v-if="selected == item.type" :style="selected == 0?'width: 72px':'width: 110px'" style="height: 5px; background: #2970FF; border-radius: 4px; margin: 5px auto;"></div>
                    </div>
                </div>
                <div style="width: 100%;margin-top: 53px;"><el-divider></el-divider></div>
                <div style="margin-left: 40px;display: flex">
                    <div style="">
                        <el-select v-model="seleswitch" placeholder="请选择" @change="selectwitch" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);border-radius: 5px">
                            <el-option
                                v-for="item in ListType"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="width: 1008px;margin-left: 20px;">
                        <el-input placeholder="请输入关键词搜索" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);border-radius: 5px" v-model="searchfor">
                            <template slot="append"><el-button @click="getBusinessInfoByType" type="primary" style="background: #2970FF;color: #FFFFFF;border-radius:0 5px 5px 0;height: 40px;"><i class="el-icon-search" style="font-size: 15.5px;"></i></el-button></template>
                        </el-input>
                    </div>
                    <div style="margin-left: 20px;">
                        <el-dropdown style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);">
                             <el-button type="primary" style="background: #2970FF;">发布模板信息<i class="el-icon-caret-bottom"></i></el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <div @click="project(1)"><el-dropdown-item icon="el-icon-folder-opened">招标信息</el-dropdown-item></div>
                                    <div @click="project(2)"><el-dropdown-item icon="el-icon-suitcase-1">采购信息</el-dropdown-item></div>
                                    <div @click="project(3)"><el-dropdown-item icon="el-icon-notebook-2">招募信息</el-dropdown-item></div>
                                    <div @click="project(4)"><el-dropdown-item icon="el-icon-collection">商务信息</el-dropdown-item></div>
                                    <div @click="project(5)"><el-dropdown-item icon="el-icon-shopping-bag-1">招商信息</el-dropdown-item></div>
                                    <div @click="project(6)"><el-dropdown-item icon="el-icon-box">需求信息</el-dropdown-item></div>
                                </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <el-table
                    :data="searchList"
                    style="width: 100%;margin-top: 5px;height: 650px;">
                    <el-table-column width="130"
                                     class="no-header">
                        <template slot-scope="scope">
                            <div style="width: 100%;overflow: hidden;margin-left: 30px">
                                <el-tag v-if="scope.row.type == 1" style="background: #3C56CF;color: #FFFFFF;float: left;border: 1px solid #3C56CF;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">招标信息</el-tag>
                                <el-tag v-if="scope.row.type == 2" style="background: #FE9544;color: #FFFFFF;float: left;border: 1px solid #FE9544;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">采购信息</el-tag>
                                <el-tag v-if="scope.row.type == 3" style="background: #40AC97;color: #FFFFFF;float: left;border: 1px solid #40AC97;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">招募信息</el-tag>
                                <el-tag v-if="scope.row.type == 4" style="background: #9079C1;color: #FFFFFF;float: left;border: 1px solid #9079C1;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">商务合作</el-tag>
                                <el-tag v-if="scope.row.type == 5" style="background: #E24F48;color: #FFFFFF;float: left;border: 1px solid #E24F48;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">招商信息</el-tag>
                                <el-tag v-if="scope.row.type == 6" style="background: #59C3D8;color: #FFFFFF;float: left;border: 1px solid #59C3D8;border-radius: 0px;height: 30px;line-height: 27px;font-size: 15px;">需求信息</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="970" label="项目标题" align="center">
                        <template slot-scope="scope" class="no-header">
                            <div style="display: flex">
                                <div @click="b('/index/details/'+scope.row.id)" style="font-size: 18px;float: left;cursor: pointer;font-weight: 600;max-width: 864px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis">{{scope.row.title}}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="报名人数" class="no-header">
                        <template slot-scope="scope">
                            <span style="font-size: 18px;color: #999999;">{{scope.row.signCount}}人<span style="color: #999999">报名</span></span>
                        </template>
                    </el-table-column>
                    <el-table-column width="200" label="结束时间" align="center" class="no-header">
                        <template slot-scope="scope">
                            <span style="font-size: 18px;color: #999999;">{{getTimeRemaining(scope.row.endTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="100" label="操作"  class="no-header">
                        <template slot-scope="scope">
                            <span v-if="user.uuid == scope.row.uuid" style="font-size: 18px;color: #2970FF;font-weight: 600;cursor: pointer" @click="cancelMyBusinessInfo(scope.row)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="searchCount > 0" style="display: flex;justify-content: center;margin-top: 10px;">
                    <div style="margin: 0px auto">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :page-size="6"
                            :total="searchCount"
                            @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="proJect" :close-on-click-modal="false" width="900px">
            <pro-ject :roomId="0" :type="1" @close="closeBusiness"></pro-ject>
        </el-dialog>
        <!--    发布采购信息-->
        <el-dialog :visible.sync="purChase" :close-on-click-modal="false" width="900px">
            <pur-chase :roomId="0" :type="2" @close="closeBusiness"></pur-chase>
        </el-dialog>
        <!--    发布招募信息-->
        <el-dialog :visible.sync="recRuit" :close-on-click-modal="false" width="900px">
            <rec-ruit :roomId="0" :type="3" @close="closeBusiness"></rec-ruit>
        </el-dialog>
        <!--    发布商务信息-->
        <el-dialog :visible.sync="buSiness" :close-on-click-modal="false" width="900px">
            <bu-siness :roomId="0" :type="4" @close="closeBusiness"></bu-siness>
        </el-dialog>
        <!--    发布招商信息-->
        <el-dialog :visible.sync="invesTment" :close-on-click-modal="false" width="900px">
            <inves-tment :roomId="0" :type="5" @close="closeBusiness"></inves-tment>
        </el-dialog>
        <!--    发布需求信息-->
        <el-dialog :visible.sync="deMand" :close-on-click-modal="false" width="900px">
            <de-mand :roomId="0" :type="6" @close="closeBusiness"></de-mand>
        </el-dialog>
    </div>
</template>
<script>
import AvatarMenu from "../AvatarMenu";
import proJect from "@/components/common/tempLate/proJect";
import purChase from "@/components/common/tempLate/purChase";
import recRuit from "@/components/common/tempLate/recRuit";
import buSiness from "@/components/common/tempLate/buSiness";
import invesTment from "@/components/common/tempLate/invesTment";
import deMand from "@/components/common/tempLate/deMand";
export default {
    name: "ask",
    components: {AvatarMenu,proJect, purChase, recRuit, buSiness, invesTment, deMand},
    data() {
        return {
            active_list:[
                {"label":"我发布的问题","type":"1"},
                {"label":"我报名的问题","type":"2"},
            ],
            selected:'1',
            ListType: [
                { id: 0, label: "全部",},
                { id: 1, label: "招标信息",  },
                { id: 2, label: "采购信息",},
                { id: 3, label: "招募信息", },
                { id: 4, label: "商务信息", },
                { id: 5, label: "招商信息", },
                { id: 6, label: "需求信息", },
            ],
            seleswitch:'',
            searchfor:'',
            dialogShow :false,
            searchList:[],
            witch: '0',
            page: 1,
            pageSize: 11,
            searchCount:'',
            proJect:false,
            purChase:false,
            recRuit:false,
            buSiness:false,
            invesTment:false,
            deMand:false,
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
    created: function () {

    },
    mounted:function(){
         this.getBusinessInfoByType()
    },
    methods: {
        //关闭招募信息弹窗
        closeBusiness(data){
            var that = this;
            if (data == 1){
                this.proJect = false;
            }else if (data == 2){
                this.purChase = false;
            }else if (data == 3){
                this.recRuit = false;
            }else if (data == 4){
                this.buSiness = false;
            }else if (data == 5){
                this.invesTment = false;
            }else if (data == 6){
                this.deMand = false;
            }
            that.page = 1;
            that.pageSize = 15;
            that.getBusinessInfoByType();
        },
        selectedswitch(name) {
            this.selected = name;
            this.getBusinessInfoByType()
        },
        getBusinessInfoByType:function(){
            var that = this;
            var param = {};
            param.type = that.selected;
            param.rewardType = that.seleswitch;
            param.like = that.searchfor;
            param.page = that.page;
            param.pageSize = that.pageSize;
            that.newApi.getBusinessInfoByType(param).then((res)=>{
                if (res.isSuccess == 1){
                    that.searchList = res.data.data;
                    that.searchCount = res.data.total;
                    that.searchfor = ""
                }
            }).catch((err)=>{
                console.log(err)
            })
        },
        selectwitch(){
            this.getBusinessInfoByType()
        },
        // 模板信息时间事件
        getTimeRemaining(endTime) {
            const now = new Date();
            const end = new Date(endTime);
            const timeDiff = end - now;

            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            // const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            return `${days}天${hours}小时${minutes}分钟`;
        },
        cancelMyBusinessInfo(row){
            let that = this;
            if (row.signCount === 0) {
                that.newApi.cancelMyBusinessInfo({
                    id:row.id
                }).then((res)=>{
                    if (res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.getBusinessInfoByType()
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            }else {
                this.utils.err('有人报名不可删除！')
            }
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getBusinessInfoByType();
        },
        project(type){
          if (!this.utils.toAuth(1)) {
            return false
          }
            if (type === 1){
                this.proJect = true;
            }else if(type === 2){
                this.purChase = true;
            }else if(type === 3){
                if (this.user.userType === 2 ){
                    this.recRuit = true
                }else {
                    this.utils.err('只有机构账号才能发布招募信息');
                    return false
                }
            }else if(type === 4){
                if (this.user.userType === 2){
                    this.buSiness = true
                }else {
                    this.utils.err('只有机构账号才能发布商务信息');
                    return false
                }
            }else if(type === 5){
                if (this.user.userType === 2 ){
                    this.invesTment = true
                }else {
                    this.utils.err('只有机构账号才能发布招商信息');
                    return false
                }
            }else if(type === 6){
                this.deMand = true
            }

        },


    }
}
</script>
<style scoped>
/deep/ .el-dialog__body {
    padding: 0px 0px;
}
/deep/ .el-table th.el-table__cell.is-leaf {
    background: #f5f7fa
}
/deep/ .center-label .cell {
    text-align: center;
}
/deep/ .el-table th.el-table__cell>.cell {
    font-size: 18px;
}
</style>
